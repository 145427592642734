import * as Sentry from '@sentry/browser'

declare const PRODUCTION: boolean
declare const DEVELOPMENT: boolean
declare const COMMITHASH: string

const ISTEST = window && window.location.host.indexOf('testing') === 0

class ErrorReporter {
  public static init(): void {
    if (PRODUCTION || ISTEST) {
      Sentry.init({
        release: `tixa-web@${COMMITHASH}`,
        dsn: 'https://4e2ce8244b63435f98f621e6af6211ac@o258281.ingest.sentry.io/1452437',
        integrations: [Sentry.browserTracingIntegration()],
        tracesSampleRate: 0.5,
        ignoreErrors: [
          '_AutofillCallbackHandler',
          '_pcmBridgeCallbackHandler',
          'Blocked a frame with origin',
          // Disable ad-blocker errors
          'net::ERR_BLOCKED_BY_CLIENT',
          "Can't find variable: gmo",
        ],
        allowUrls: [/tixa\.hu/i],
        denyUrls: [
          // Facebook flakiness
          /facebook\.com/i,
          // Facebook blocked
          /connect\.facebook\.net\/en_US\/all\.js/i,
          // Safari extensions
          /^safari-web-extension:\/\//i,
          // Chrome extensions
          /extensions\//i,
          /^chrome:\/\//i,
          // Other plugins
          /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
          // Localhost
          /localhost/i,
          // Ad-blocked analytics requests
          /region1\.analytics\.google\.com/i,
          /g\/collect/i,
        ],
      })
    }
  }

  public static exception(error: Error): void {
    if (!(error instanceof Error)) {
      return
    }
    if (DEVELOPMENT || ISTEST) {
      // tslint:disable-next-line:no-console
      console.error(error)
    }
    if (PRODUCTION) {
      Sentry.captureException(error)
    }
  }
  public static message(message: string, severity: Sentry.SeverityLevel): void {
    if (DEVELOPMENT || ISTEST) {
      if (severity === 'error' || severity === 'fatal') {
        // tslint:disable-next-line:no-console
        console.error(message)
      }
      if (severity === 'info') {
        // tslint:disable-next-line:no-console
        console.info(message)
      }
      if (severity === 'warning') {
        // tslint:disable-next-line:no-console
        console.warn(message)
      }
      if (severity === 'debug') {
        // tslint:disable-next-line:no-console
        console.debug(message)
      }
    }
    if (PRODUCTION) {
      Sentry.captureMessage(message, severity)
    }
  }
}

export { ErrorReporter }
